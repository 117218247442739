<template>
	<page-container :page="page" :locale="locale" class="index">
		<div class="top-bar">
			<div class="columns column6 align-left">
				<div v-if="page" class="language-navigation" @click="toggleLanguageMenu">
					<div v-if="langmenu.length" class="lang-select">
						<font-awesome-icon :icon="`fal fa-globe`" size="1x" />
						<span>{{ locale }}</span>
						<font-awesome-icon :icon="`fal fa-chevron-down`" size="1x" />
						<div class="lang-wrapper" :class="{ active: showLanguageMenu }">
							<div v-for="item in langmenu" :key="item.filename" class="language">
								<nuxt-link class="flag" :class="item.language" :to="item.filename">
									<span>{{ item.language }}</span>
								</nuxt-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="columns column6 align-right">
				<p>
					<font-awesome-icon class="marker" icon="fa-light fa-location-dot" size="1x" />
					<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}, </span>
					<span>{{ defaults[locale].website.city }}</span>
				</p>
			</div>
		</div>

		<main-content
			:title="page.title || page.header"
			:content="page.content"
			:extracontent="page.extraContent"
			:defaults="defaults[locale].website"
			:socials="socials"
		/>
		<div class="row images-row">
			<div v-for="image in page.images" :key="image.ID" class="image">
				<picture>
					<source v-if="image.imageWebp" :srcset="image.imageWebp" type="image/webp" />
					<source :srcset="image.image" />
					<img class="object-fit" :src="image.image" :alt="image.imageAlt" />
				</picture>
			</div>
		</div>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showLanguageMenu = ref(false);

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};
</script>

<style lang="scss" scoped>
.images-row {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
	margin: 50px auto 100px;

	.image {
		width: calc(50% - 20px);
		margin: 0;
	}
}

.top-bar {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	.align-right {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
	}

	.align-left {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	.marker {
		font-size: 20px;
		color: #000;
		margin: 0 10px 0 0;
	}

	p {
		font-size: 15px;
	}
}

.lang-select {
	padding: 8px 18px;
	margin: 0 18px 0 10px;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 13px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	span {
		margin: 0 4px;
	}

	a {
		color: #000;
		display: flex;
		flex-flow: row nowrap;
		gap: 8px;
		margin: 7px 0;
		place-content: center flex-start;
		width: 100%;
		text-decoration: none;
	}

	svg {
		font-size: 12px;
	}

	.language {
		margin: 0;

		a.flag {
			margin: 2px 0;
			flex-flow: row nowrap;
			place-content: center flex-start;
			gap: 2px;
		}
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 13px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	z-index: 90;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

.lang-nav,
.lang-select {
	.language {
		.flag::before {
			content: '';
			width: 16px;
			position: relative;
			top: 2px;
			height: 16px;
			margin-right: 6px;
			display: inline-block;
		}

		.flag.en::before {
			background: url('~/assets/images/en.png') no-repeat;
		}

		.flag.nl::before {
			background: url('~/assets/images/nl.png') no-repeat;
		}

		.flag.de::before {
			background: url('~/assets/images/de.png') no-repeat;
		}

		.flag.fr::before {
			background: url('~/assets/images/fr.png') no-repeat;
		}

		.flag.es::before {
			background: url('~/assets/images/es.png') no-repeat;
		}
	}

	img {
		width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}

	a {
		width: 100%;
		float: left;
		color: var(--body-color);
		text-decoration: none;
		margin: 4px 0;
		text-transform: uppercase;

		&.active {
			color: var(--cta-color);
		}
	}
}

.language-navigation .fa-globe {
	margin: 0 2px 0 0;
	color: var(--body-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 880px) {
	.images-row {
		margin: 20px auto;

		.image {
			width: 92%;
			margin: 0 auto 20px;
		}
	}

	.top-bar {
		flex-flow: row wrap;
		width: 100%;

		.column6 {
			width: 70%;
			justify-content: center;
			padding: 0 5px;

			&:first-child {
				width: 30%;
			}

			.socials,
			:deep(.socials) {
				justify-content: center;

				a {
					margin: 10px 5px 5px;
				}
			}
		}
	}
}
</style>
